import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
  Center,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Button,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { FaSearch, FaUniversity } from 'react-icons/fa';
import useDebounce from '../utils/useDebounce';
import { observer } from 'mobx-react-lite';
import studentStore from '../store/studentSpecializations';
import { Link, useParams } from 'react-router-dom';

const StudentPage = observer(({ student = studentStore }) => {
  const { document_number } = useParams();

  useEffect(() => {
    student.load({ document_number });
  }, []);

  return (
    <Layout>
      {student.isLoading ? (
        <VStack p="20px 20px" align="center" w="100%">
          <Stack w="60%" h="36px">
            <Skeleton w="100%" h="100%" />
          </Stack>
          <Stack w="80%" justify="stretch" pt="40px">
            <Skeleton w="100%" h="36px" />
          </Stack>
        </VStack>
      ) : (
        <VStack p="20px 20px" align={{ base: 'flex-start', xlg: 'center' }} w="100%">
          <Text fontSize="16px" fontWeight="700" pt="40px" w="80%">
            {student.list.length
              ? `Номер документа: ${document_number.slice(0,3)+'-'+document_number.slice(3,6)+'-'+document_number.slice(6,9)+' '+document_number.slice(9,11)}`
              : `Нет данных по документу ${document_number.slice(0,3)+'-'+document_number.slice(3,6)+'-'+document_number.slice(6,9)+' '+document_number.slice(9,11)}`}
          </Text>

          {student.list.length && (
            <TableContainer pt="40px" whiteSpace="normal" overflowX="visible"
            overflowY="visible"
            maxW="999999px">
              <Box as={Table} variant="simple" maxW="999999px">
                <Thead>
                  <Tr>
                    <Th>Университет</Th>
                    <Th>Департамент</Th>
                    <Th>Специализация</Th>
                    <Th>Баллы</Th>
                    <Th>Текущий проходной балл</Th>
                    <Th>Проходной балл по поданным согласиям</Th>
                    <Th>Проходной балл прошлого года</Th>
                    <Th>Вероятность</Th>
                    <Th>Согласие подано</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {student.list.map(elem => {
                    return (
                      <Tr
                        key={'university-specialization-' + elem.spec_info.id}
                        bgColor={elem.agreement ? 'green.300' : 'transparent'}
                      >
                        <Td>{elem.spec_info.university_name}</Td>
                        <Td>{elem.spec_info.department}</Td>
                        <Td>{elem.spec_info.name}</Td>
                        <Td>{elem.exam_score}</Td>
                        <Td>{elem.threshold_now}</Td>
                        <Td>{elem.threshold_agreement}</Td>
                        <Td>{elem.spec_info.last_pass_score}</Td>
                        <Td>{elem.probability}</Td>
                        <Td>{elem.agreement ? '+' : '-'}</Td>
                        <Td>
                          <Link
                            to={`/specialization-result/${elem.spec_info.id}`}
                          >
                            <Button size="xs" colorScheme="blue">
                              Информация
                            </Button>
                          </Link>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Box>
            </TableContainer>
          )}
        </VStack>
      )}
    </Layout>
  );
});

export default StudentPage;
