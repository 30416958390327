import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { FaUniversity } from 'react-icons/fa';

export const UniversityCard = ({ name, id }) => {
  return (
    <Box as={Link} to={`/specializations/${id}/${name}`} width="100%">
      <HStack
        w="100%"
        h="100%"
        align="center"
        justify="start"
        rounded="md"
        bgColor="gray.100"
        p="15px 30px"
      >
        <Box fontSize="24px" color="gray.400" mr="20px">
          <FaUniversity />
        </Box>
        <Text textAlign="center">{name}</Text>
      </HStack>
    </Box>
  );
};
