import {
  Grid,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
  Center,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { FaSearch } from 'react-icons/fa';
import useDebounce from '../utils/useDebounce';
import { observer } from 'mobx-react-lite';
import { UniversityCard } from '../components/UniversityCard';
import universitiesStore from '../store/universities';

const HomePage = observer(({ universities = universitiesStore }) => {
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    universities.load();
  }, []);

  const searchHandler = useCallback(
    (searchStr = '') => {
      if (!searchStr.trim()) {
        setSearchData([]);
      } else {
        const newData = universities.list.filter(item =>
          item.name.toLowerCase().includes(searchStr.trim().toLowerCase())
        );
        if (!newData.length) {
          setSearchData(['Not Found']);
        } else {
          setSearchData(newData);
        }
      }
    },
    [searchData]
  );

  useEffect(() => {
    searchHandler(debouncedSearchValue);
  }, [debouncedSearchValue]);


  return (
    <Layout>
      <VStack p="20px 20px" align="center" w="100%">
        <Heading>Список университетов</Heading>
        <HStack w="60%" justify="stretch" pt="40px">
          <InputGroup>
            <InputLeftAddon>
              <FaSearch />
            </InputLeftAddon>
            <Input
              type="text"
              placeholder="Введите название университета"
              w="100%"
              variant="filled"
              value={searchValue}
              onInput={e => setSearchValue(e.target.value)}
            />
          </InputGroup>
        </HStack>
        <VStack
          pt="40px"
          w="60%"
          gap="5px"
        >
          {universities.isLoading ? (
            <>
              <Stack w="100%" h="100%">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="100%" h="100%">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="100%" h="100%">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="100%" h="100%">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="100%" h="100%">
                <Skeleton w="100%" h="100%" />
              </Stack>
            </>
          ) : searchData.length ? (
            searchData[0] === 'Not Found' ? (
              <Center w="100%" h="100%">
                <Text>Нет результатов по вашему запросу</Text>
              </Center>
            ) : (
              searchData.map(elem => {
                return (
                  <UniversityCard
                    name={elem.name}
                    id={elem.id}
                    key={'university-item-search-' + elem.id}
                  />
                );
              })
            )
          ) : (
            universities.list.map(elem => {
              return (
                <UniversityCard
                  name={elem.name}
                  id={elem.id}
                  key={'university-item-' + elem.id}
                />
              );
            })
          )}
        </VStack>
      </VStack>
    </Layout>
  );
});

export default React.memo(HomePage);
