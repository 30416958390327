import { Button, HStack, Text, VStack, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import {TheFooter} from "./TheFooter";
import Logo from '../assets/logo.jpg'

const articles = [
    {
        href: '/license',
        value: 'Лицензионное соглашение'
    },
    {
        href: '/inform',
        value: 'Информация и документация по ПО'
    },
    {
        href: '/user-guide',
        value: 'Руководство пользователя'
    },
]

const Layout = ({ children }) => {
  return (
    <VStack w="100%" h="100vh">
      <HStack
        w="100%"
        h="60px"
        shadow="md"
        bgColor="white"
        p="0 20px"
        align="center"
        position="fixed"
        zIndex="999"
      >
        <Link to="/">
            <Image src={Logo} alt="logo" boxSize='100px' objectFit='contain' />
        </Link>

        <HStack w="100%" h="100%" justify="flex-end">
          <Link to="/">
            <Button colorScheme="gray" w="160px">
              <Text fontWeight="400">Университеты</Text>
            </Button>
          </Link>
          <Link to="/student">
            <Button colorScheme="gray" w="160px">
              <Text fontWeight="400">Студенты</Text>
            </Button>
          </Link>
        </HStack>
      </HStack>
      <VStack mt="60px!important" w="100%" pb="100px">
      {children}
      </VStack>
      <TheFooter articles={articles} />
    </VStack>
  );
};

export default Layout;
