import Layout from "../components/Layout";

const h2 = {
    fontSize: '24px',
}

export const Inform = () => {
    return (
        <Layout>
            <div style={{maxWidth: '1000px', display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '0 auto', paddingTop: '24px', gap: '8px'}}>
                <h1 style={{fontSize: '32px'}}>Информация и документация по программе</h1>
                <h2 style={h2}>Описание функциональных характеристик</h2>
                <p>Консалтинговая онлайн-платформа для поступающих с возможностью оценки шансов на зачисление на основе интеллектуализированной системы поддержки принятия решений «Выбор будущего» позволяет реализовать следующий функционал:</p>
                <ol style={{listStyleType: 'disc', paddingLeft: '24px'}}>
                    <li>Парсинг данных списков поступления с  сайтов российских вузов.</li>
                    <li>Отображение основной информации для абитуриентов по ВУЗам  в разрезе специализаций бюджет/платное (количество мест, текущий проходной балл, проходной балл по поданным согласиям и проходной балл прошлого года).</li>
                    <li>Отображение ранжированного списка поступающих в разрезе университета и специальности.</li>
                    <li>Расчет вероятности поступления для каждого подавшего заявление абитуриента в рамках специализации (платно/бюджет).</li>
                    <li>Просмотр на какие специализации каких вузов подал заявления абитуриент и его шансы на  поступление.</li>
                </ol>
                <h2 style={h2}>Информация необходимая для установки ПО</h2>
                <p>Доступ к программному обеспечению осуществляется через браузер в устройстве пользователя, по адресу <a style={{color: 'blue'}} href="https://think.confreg.org" target="_blank">https://think.confreg.org</a>.</p>
                <p>Устройства пользователей должны поддерживать операционную систему и браузер, с установленными обновлениями, датированными не ранее 2016 года.</p>
                <h2 style={h2}>Информация необходимая для эксплуатации ПО.<br />Инструкция по эксплуатации.</h2>
                <p>Доступ ко всей функциональности программы реализован через веб-интерфейс. Система предоставляет возможность получения доступа к функциональности через основные веб-браузеры:</p>
                <ol style={{paddingLeft: '24px'}}>
                    <li>Microsoft Edge от версии 99 и выше;</li>
                    <li>Google Chrome версии 105 и выше;</li>
                    <li>Opera версии 103 и выше;</li>
                    <li>Mozilla Firefox версии 102 и выше;</li>
                    <li>Safari версии 12 и выше.</li>
                </ol>
                <p>Здесь вы можете просмотреть <a style={{color: 'blue'}} target="_blank" href="/user-guide">ИНСТРУКЦИЮ ПОЛЬЗОВАТЕЛЯ</a></p>
                <h2 style={h2}>Поддержание жизненного цикла программы</h2>
                <p>Сопровождение и поддержка пользователей программы «Выбор будущего», в том числе устранение неисправностей, выявленных в ходе эксплуатации, выполняется силами штатных сотрудников отдела ИТ ООО «Триалог» посредством:</p>
                <ol style={{listStyleType: 'disc', paddingLeft: '24px'}}>
                    <li>регулярного массового автоматического обновления ПО в соответствии с собственным планом разработки;</li>
                    <li>единичного обновления ПО по запросу пользователя в адрес специалиста службы технической поддержки (ntrusova@trialogue-org.ru).</li>
                </ol>
                <p>Развитие и модернизация программы также выполняется специалистами отдела ИТ  ООО «Триалог» по предложениям пользователей на почту ntrusova@trialogue-org.ru.</p>
            </div>
        </Layout>
    )
}
