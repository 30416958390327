import { makeAutoObservable } from 'mobx';
import API from '../api';

class SpecializationsStore {
  isLoading = false;

  list = [];

  currentUniversityName = null;

  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  load({ university_id}) {
    this.isLoading = true;
    this.list = [];

    API.getSpecializations({ university_id })
      .then(data => {
        this.list = data;
      })
      .catch(error => {
        this.error = error;
      })
      .finally(() => (this.isLoading = false));
  }
}

export default new SpecializationsStore();
