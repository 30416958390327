import {
    Grid,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    Text,
    VStack,
    Center,
    Stack,
    Skeleton,
    Button,
  } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { FaSearch } from 'react-icons/fa';
import useDebounce from '../utils/useDebounce';
import { UniversityCard } from '../components/UniversityCard';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
  
  const StudentSearchPage = () => {
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
      const prepearedSearchValue = searchValue.split('-').join('').split(' ').join('')
        navigate(`/student/${prepearedSearchValue}`, { replace: false });
    }
  
    return (
      <Layout>
        <VStack p="20px 20px" align="center" w="100%">
          <Heading>Введите номер документа студента</Heading>
          <HStack w="60%" justify="stretch" pt="40px">
            <InputGroup>
              <Input 
                as={InputMask}
                type="text"
                placeholder="Введите номер документа"
                w="100%"
                variant="filled"
                mask={'999-999-999 99'}
                value={searchValue}
                onChange={e => {
                  console.log(e.target.value);
                  setSearchValue(e.target.value)
                }}
                >
              </Input>
            </InputGroup>
            <Button colorScheme="blue" h="40px" onClick={handleSearch} isDisabled={!searchValue.trim().match(/\d{3}-\d{3}-\d{3} \d{2}/gm)}>
                Поиск
            </Button>
          </HStack>
        </VStack>
      </Layout>
    );
  };
  
  export default StudentSearchPage;
  