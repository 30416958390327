import {
  Grid,
  Text,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Stack,
  Skeleton,
  Link,
  Button,
  Box,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import specializationResultStore from '../store/specializationResult';

const SpecializationResultPage = observer(
  ({ specializationResult = specializationResultStore }) => {
    const { id } = useParams();

    useEffect(() => {
      specializationResult.load({ specialization_id: id });
    }, []);

    return (
      <Layout>
        {specializationResult.isLoading ? (
          <VStack p="20px 0" align="center" w="100%">
            <Grid
              w="80%"
              templateColumns="1fr 2fr"
              autoRows="36px"
              mt="40px"
              bgColor="gray.100"
              rounded="md"
              p="20px 20px"
            >
              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>

              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>

              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>

              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>

              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>

              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>
              <Stack w="60%" h="14px">
                <Skeleton w="100%" h="100%" />
              </Stack>
            </Grid>

            <Stack w="80%" pt="40px" h="120px">
              <Skeleton w="100%" h="100%" />
            </Stack>
          </VStack>
        ) : (
          specializationResult.result && (
            <VStack p="20px 20px" align={{ base: 'flex-start', lg: 'center' }} w="100%">
              <Grid
                minW="80vw"
                maxW="2400px"
                templateColumns="1fr 2fr"
                autoRows="max-content"
                mt="40px"
                rowGap="12px"
                columnGap="8px"
                bgColor="gray.100"
                rounded="md"
                p="20px 20px"
              >
                <Text fontSize="14px" fontWeight="700">
                  Университет
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.spec_info.university_name}
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  Департамент
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.spec_info.department}
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  Специализация
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.spec_info.name}
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  Время формирования отчета
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.timestamp}
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  Количество мест
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.places}
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  Текущий проходной балл
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.threshold_now}
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  Проходной балл по поданным согласиям
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.threshold_agreement}
                </Text>

                <Text fontSize="14px" fontWeight="700"  maxW="100%">
                  Проходной балл прошлого года
                </Text>
                <Text fontSize="14px" fontWeight="400" maxW="100%">
                  {specializationResult.result.spec_info.last_pass_score}
                </Text>

                <Text fontSize="14px" fontWeight="700">
                  Источники
                </Text>
                <VStack w="100%" align="flex-start"  maxW="100%" whiteSpace="normal">
                  {specializationResult.result.spec_info.ranking_url.map(
                    elem => {
                      return (
                        <Link href={elem} target="_blank" key={elem}>
                          <Text fontSize="14px" fontWeight="400">
                            {elem}
                          </Text>
                        </Link>
                      );
                    }
                  )}
                </VStack>
              </Grid>

              {specializationResult.result.context ? (
                <TableContainer
                  pt="40px"
                  whiteSpace="normal"
                  overflowX="visible"
                  overflowY="visible"
                  maxW="999999px"
                >
                  <Box as={Table} variant="simple" maxW="999999px">
                    <Thead>
                      <Tr>
                        <Th>№</Th>
                        <Th>Документ</Th>
                        <Th>Баллы</Th>
                        <Th>БВИ</Th>
                        <Th>Согласие</Th>
                        <Th>Согласие в другом месте</Th>
                        <Th>Вероятность</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {specializationResult.result.context.map(elem => {
                        return (
                          <Tr
                            key={'specialization-result-' + elem.order_number}
                            bgColor={
                              elem.agreement
                                ? 'red.300'
                                : elem.agreement_another
                                ? 'green.300'
                                : elem.order_number >
                                  specializationResult.result.places
                                ? 'gray.200'
                                : 'transparent'
                            }
                            borderBottom={
                              elem.order_number ==
                              specializationResult.result.places
                                ? '5px solid #C53030'
                                : 'none'
                            }
                          >
                            <Td>{elem.order_number}</Td>
                            <Td>{elem.document_number}</Td>
                            <Td>{elem.exam_score}</Td>
                            <Td>{elem.without_tests ? '+' : '-'}</Td>
                            <Td>{elem.agreement ? '+' : '-'}</Td>
                            <Td>{elem.agreement_another ? '+' : '-'}</Td>
                            <Td>{elem.probability}</Td>
                            <Td>
                              <RouterLink
                                to={`/student/${elem.document_number.split('-').join('')}`}
                              >
                                <Button size="xs" colorScheme="blue">
                                  Информация
                                </Button>
                              </RouterLink>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Box>
                </TableContainer>
              ):(
                <Text pt="40px">Нет данных</Text>
              )}
            </VStack>
          )
        )}
      </Layout>
    );
  }
);

export default SpecializationResultPage;
