import { makeAutoObservable, reaction } from 'mobx';
import API from '../api';

class SpecializationResultStore {
  isLoading = false;

  result = null;

  error = null;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.result,
      result => {
        this.updateTimestampFormat(result);
        this.splitURLs(result);
      }
    );
  }

  updateTimestampFormat(result) {
    if (result) {
      const date = new Date(result.timestamp);

      this.result.timestamp = date.toLocaleString();
    }
  }

  splitURLs(result) {
    if (result) {
      const urls = result.spec_info.ranking_url.split('|');

      this.result.spec_info.ranking_url = urls;
    }
  }

  load({ specialization_id }) {
    this.isLoading = true;
    this.result = null;

    API.getSpecializationResult({ specialization_id })
      .then(data => {
        this.result = data;
      })
      .catch(error => {
        this.error = error;
      })
      .finally(() => (this.isLoading = false));
  }
}

export default new SpecializationResultStore();
