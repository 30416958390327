import Layout from "../components/Layout";
import Img1 from '../assets/1.png'
import Img2 from '../assets/2.png'
import Img3 from '../assets/3.png'
import Img4 from '../assets/4.png'
import Img5 from '../assets/5.png'

const h2 = {
    fontSize: '24px',
}

const h3 = {
    fontSize: '20px',
}

const img = {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
}

export const UserGuide = () => {
    return (
        <Layout>
            <div style={{maxWidth: '1000px', display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '0 auto', paddingTop: '24px', gap: '8px'}}>
                <h1 style={{fontSize: '32px'}}>Инструкция для пользователя онлайн-платформы с возможностью оценки шансов на зачисление</h1>
                <h2 style={h2}>Краткая информация</h2>
                <p>Предиктивные инструменты для оценки шансов поступления в настоящий момент являются востребованным продуктом в виду сложившейся конкурсной ситуации в высших учебных заведениях. В последнее время начиная с 2020 года, в том числе под влиянием пандемии существенно меняется порядок подачи заявлений и процедуры поступления в высшие учебные заведения. С 2021 года зачисление абитуриентов в рамках контрольных цифр приема происходит не поэтапно, а единым списком в одну волну.</p>
                <p>Набор инструментов для поддержки принятия решений в выборе специальности и вуза на основе технологии искусственного интеллекта являются уникальным предложением и позволяют использовать передовые программные предложения для решения жизненно важных вопросов широкому кругу пользователей.</p>
                <p>По любым возникающим вопросам вы можете обратиться в поддержку: ntrusova@trialogue-org.ru</p>
                <p>Инструкция разделена на несколько тематических разделов.
                    Первый посвящён краткому обзору основного функционала платформы, а также требованиям к браузеру и устройствам.
                    Второй блок предоставит краткую инструкцию по работе сразделом «Университеты».
                    Третий раздел обращается к разделу «Студенты».
                    В конце документа вы найдёте информацио о поддержке пользователей.
                </p>
                <p>С лицензионным соглашением вы можете ознакомиться, пройдя по ссылке <a style={{color: 'blue'}} href="https://consult.dev.think24.ru/license">https://consult.dev.think24.ru/license</a></p>
                <h2 style={h2}>Основные требования и функционал платформы</h2>
                <p>Консалтинговая онлайн-платформа для поступающих с возможностью оценки шансов на зачисление на основе интеллектуализированной системы поддержки принятия решений «Выбор будущего» позволяет реализовать следующий функционал:</p>
                <ol style={{paddingLeft: '24px', listStyleType: 'disc'}}>
                    <li>Парсинг данных списков поступления с  сайтов российских вузов.</li>
                    <li>Отображение основной информации для абитуриентов по ВУЗам  в разрезе специализаций бюджет/платное (количество мест, текущий проходной балл, проходной балл по поданным согласиям и проходной балл прошлого года).</li>
                    <li>Отображение ранжированного списка поступающих в разрезе университета и специальности.</li>
                    <li>Расчет вероятности поступления для каждого подавшего заявление абитуриента в рамках специализации (платно/бюджет).</li>
                    <li>Просмотр на какие специализации каких вузов подал заявления абитуриент и его шансы на  поступление.</li>
                </ol>
                <h3 style={h3}>Информация необходимая для установки ПО</h3>
                <p>Доступ к программному обеспечению осуществляется через браузер в устройстве пользователя, по адресу
                    <a href="https://think.confreg.org">https://think.confreg.org</a>.
                    Устройства пользователей должны поддерживать операционную систему и браузер, с установленными обновлениями, датированными не ранее 2016 года.
                </p>
                <h3 style={h3}>Информация необходимая для эксплуатации ПО</h3>
                <p>Доступ ко всей функциональности программы реализован через веб-интерфейс. Система предоставляет возможность получения доступа к функциональности через основные веб-браузеры:</p>
                <ol style={{paddingLeft: '24px', listStyleType: 'disc'}}>
                    <li>Microsoft Edge от версии 99 и выше;</li>
                    <li>Google Chrome версии 105 и выше;</li>
                    <li>Opera версии 103 и выше;</li>
                    <li>Mozilla Firefox версии 102 и выше;</li>
                    <li>Safari версии 12 и выше.</li>
                </ol>
                <h2 style={h2}>Структура платформы</h2>
                <p>Платформа разделена на 2 основных пользовательских раздела:</p>
                <ol style={{paddingLeft: '24px', listStyleType: 'disc'}}>
                    <li>Университеты</li>
                    <li>Студенты</li>
                </ol>
                <p>Переход между разделами можно осуществить с помощью клика на соответствующую кнопку в верхнем правом углу экрана.</p>
                <p>Раздел «Университеты» позволяет осуществлять поиск по университетам, просматривать доступные образовательные программы и текущую конкурсную ситуацию.</p>
                <p>Раздел «Студенты» позволяет осуществлять поиск по номеру СНИЛС, просматривать поданные заявления и текущее положение в списках конкретного студента.</p>
                <h2 style={h2}>Раздел «Университеты»</h2>
                <p>Платформа предоставляет информацию по конкурсной ситуации на каждой специальности выбранного университета.</p>
                <p>Чтобы просмотреть ранжированные списки абитуриентов конкретного направления, необходимо в поиске ввести название университета (рис. 1).</p>

                <div style={img}>
                    <img src={Img1} alt="1"/>
                    <figcaption>Рисунок 1. Поиск университета на платформе</figcaption>
                </div>
                <p>Перейти кликом по названию в список реализуемых образовательных программ (рис. 2).</p>

                <div style={img}>
                    <img src={Img2} alt="2"/>
                    <figcaption>Рисунок 2. Поиск образовательной программы</figcaption>
                </div>

                <p>Клик по кнопке «Информация» переведет вас на страницу ранжированного списка абитуриентов по выбранной специальности (рис. 3).</p>
                <div style={img}>
                    <img src={Img3} alt="3"/>
                    <figcaption>Рисунок 3. Список абитуриентов</figcaption>
                </div>

                <p>В представленном списке отображается:</p>
                <ol style={{paddingLeft: '24px', listStyleType: 'disc'}}>
                    <li>Порядковый номер (колонка 1)</li>
                    <li>номер СНИЛС абитуриента (колонка 2)</li>
                    <li>наличие права поступления Без Вступительных Испытаний (колонка 3)</li>
                    <li>предоставил ли абитуриент согласие на поступление на выбранное направление (колонка 4)</li>
                    <li>предоставил ли абитуриент согласие на поступление на другое направление (колонка 5)</li>
                    <li>рассчитанная вероятность поступления (колонка 6)</li>
                </ol>

                <p>Красным цветом подсвечены абитуриенты, предоставившие согласие на данное направление, зеленым – на другом направлении или в другом вузе.</p>
                <p>Абитуриенты с порядковым номером большим, чем количество бюджетных мест, подсвечены серым и отделены красной чертой (рис. 4).</p>

                <div style={img}>
                    <img src={Img4} alt="3"/>
                    <figcaption>Рисунок 4. Список абитуриентов</figcaption>
                </div>

                <h2 style={h2}>Раздел «Студенты»</h2>
                <p>Платформа предоставляет информацию на какие направления подал заявление студент.
                    Поиск среди студентов осуществляется по номеру СНИЛС.
                </p>

                <div style={img}>
                    <img src={Img5} alt="5"/>
                    <figcaption>Рисунок 5. Список направлений для выбранного студента</figcaption>
                </div>

                <p>В представленном списке отображается:</p>
                <ol style={{paddingLeft: '24px', listStyleType: 'disc'}}>
                    <li>Университет (колонка 1)</li>
                    <li>Факультет (колонка 2)</li>
                    <li>Специализация (колонка 3)</li>
                    <li>Суммарные баллы студента с учетом персональных достижений (колонка 4)</li>
                    <li>Проходной балл на момент поиска (колонка 5)</li>
                    <li>Проходной балл на момент поиска с учетом поданных согласий (колонка 6)</li>
                    <li>«0» значит, что на пока никто не подал согласия (колонка 7)</li>
                    <li>Проходной балл прошлого года (колонка 8)</li>
                    <li>Рассчитанная вероятность поступления (колонка 9)</li>
                    <li>Отметка о подаче согласия (колонка 10)</li>
                </ol>
                <p>Клик по кнопке «Информация» позволяет перейти к полному конкурсному списку выбранной специальности.</p>
                <h2 style={h2}>Поддержка пользователей платформы</h2>

                <p>Если у вас возникают затруднения с входом на платформу, вы можете обратиться в поддержку по указанному адресу ntrusova@trialogue-org.ru. Для более быстрого и точного решения проблемы, оставьте по возможности подробное описание проблемы и приложите пошаговые скриншоты или снимки экрана на телефон.</p>
            </div>
        </Layout>
    )
}
