import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import SpecializationsPage from './pages/SpecializationsPage';
import SpecializationResultPage from './pages/SpecializationResultPage';
import StudentPage from './pages/StudentPage';
import StudentSearchPage from './pages/StudentSearchPage';
import {License} from "./pages/License";
import {Inform} from "./pages/Inform";
import {UserGuide} from "./pages/UserGuide";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} exact/>
          <Route path="/specializations/:id/:university_name" element={<SpecializationsPage />} exact/>
          <Route path="/specialization-result/:id" element={<SpecializationResultPage />} exact/>
          <Route path="/student/" element={<StudentSearchPage />} exact/>
          <Route path="/student/:document_number" element={<StudentPage />} exact/>
          <Route path="/license" element={<License />} exact/>
          <Route path="/inform" element={<Inform />} exact/>
          <Route path="/user-guide" element={<UserGuide />} exact/>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
