import { makeAutoObservable } from 'mobx';
import API from '../api';

class StudentSpecializationsStore {
  isLoading = false;

  list = [];

  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  load({ document_number }) {
    this.isLoading = true;
    this.list = [];

    API.getStudentSpecializations({ document_number })
      .then(data => {
        this.list = data;
      })
      .catch(error => {
        this.error = error;
      })
      .finally(() => (this.isLoading = false));
  }
}

export default new StudentSpecializationsStore();
