import { Center, Text } from '@chakra-ui/react';
import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <Center h="100%" w="100%">
        <Text>Not Found</Text>
      </Center>
    </Layout>
  );
};

export default NotFoundPage;
