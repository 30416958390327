import {Link} from "react-router-dom";

export const TheFooter = ({articles}) => {
    return (
        <div style={{position: 'fixed', zIndex: 999, bottom: 0, left: 0, padding: '24px', background: '#f0f0f0', width: '100vw', display: 'flex', gap: '24px', alignItems: 'center'}}>
            {articles.map(item => {
                return (
                    <Link key={item.value} to={item.href}>{item.value}</Link>
                )
            })}
        </div>
    )
}
