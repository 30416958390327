import {
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
  Center,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { FaSearch } from 'react-icons/fa';
import useDebounce from '../utils/useDebounce';
import { observer } from 'mobx-react-lite';
import specializationsStore from '../store/specializations';
import { Link, useParams } from 'react-router-dom';

const SpecializationsPage = observer(
  ({
    specializations = specializationsStore,
  }) => {
    const [searchData, setSearchData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 300);

    const { id, university_name } = useParams();

    useEffect(() => {
        specializations.load({
          university_id: id
        });
    }, []);

    const searchHandler = useCallback(
      (searchStr = '') => {
        if (!searchStr.trim()) {
          setSearchData([]);
        } else {
          const newData = specializations.list.filter(item =>
            item.name.toLowerCase().includes(searchStr.trim().toLowerCase())
          );
          if (!newData.length) {
            setSearchData(['Not Found']);
          } else {
            setSearchData(newData);
          }
        }
      },
      [searchData]
    );

    useEffect(() => {
      searchHandler(debouncedSearchValue);
    }, [debouncedSearchValue]);

    return (
      <Layout>
        {specializations.isLoading ? (
          <VStack p="20px 20px" align="center" w="100%">
            <Stack w="60%" h="36px">
              <Skeleton w="100%" h="100%" />
            </Stack>
            <Stack w="80%" justify="stretch" pt="40px">
              <Skeleton w="100%" h="36px" />
            </Stack>
          </VStack>
        ) : (
          <VStack p="20px 20px" align="center" w="100%">
            <Heading maxW="80%">
              Специальности {university_name}
            </Heading>
            <HStack w="80%" justify="stretch" pt="40px">
              <InputGroup>
                <InputLeftAddon>
                  <FaSearch />
                </InputLeftAddon>
                <Input
                  type="text"
                  placeholder="Введите название специальности"
                  w="100%"
                  variant="filled"
                  value={searchValue}
                  onInput={e => setSearchValue(e.target.value)}
                />
              </InputGroup>
            </HStack>

            {searchData.length ? (
              searchData[0] === 'Not Found' ? (
                <Center w="80%" pt="40px">
                  <Text>Нет результатов</Text>
                </Center>
              ) : (
                <TableContainer
                  w="80%"
                  pt="40px"
                  whiteSpace="normal"
                  maxW="80%"
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th maxW="40%">Департамент</Th>
                        <Th maxW="40%">Название</Th>
                        <Th maxW="20%"></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {searchData.map(elem => {
                        return (
                          <Tr key={'university-specialization-' + elem.id}>
                            <Td maxW="40%">{elem.department}</Td>
                            <Td maxW="40%">{elem.name}</Td>
                            <Td maxW="20%">
                              <Link to={`/specialization-result/${elem.id}`}>
                                <Button size="xs" colorScheme="blue">
                                  Информация
                                </Button>
                              </Link>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              )
            ) : specializations.list.length ? (
              <TableContainer w="80%" pt="40px" whiteSpace="normal" maxW="80%">
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th maxW="40%">Департамент</Th>
                      <Th maxW="40%">Название</Th>
                      <Th maxW="20%"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {specializations.list.map(elem => {
                      return (
                        <Tr key={'university-specialization-' + elem.id}>
                          <Td maxW="40%">{elem.department}</Td>
                          <Td maxW="40%">{elem.name}</Td>
                          <Td maxW="20%">
                            <Link to={`/specialization-result/${elem.id}`}>
                              <Button size="xs" colorScheme="blue">
                                Информация
                              </Button>
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Center w="80%" pt="40px">
                <Text>Нет данных</Text>
              </Center>
            )}
          </VStack>
        )}
      </Layout>
    );
  }
);

export default SpecializationsPage;
