import axios from 'axios';
const api-consult.dev.think24.ru = process.env.REACT_APP_API_URL;

const getAPIClient = axios.create({
  baseURL: api-consult.dev.think24.ru,
});

const API = {
  getUniversities: async () => {
    const { data } = await getAPIClient.get(
      `${api-consult.dev.think24.ru}/consult/universities`
    );
    return data;
  },
  getSpecializations: async ({ university_id }) => {
    const { data } = await getAPIClient.get(
      `${api-consult.dev.think24.ru}/consult/specializations`,
      {
        params: {
          university_id,
        },
      }
    );
    return data;
  },
  getStudentSpecializations: async ({ document_number }) => {
    const { data } = await getAPIClient.get(
      `${api-consult.dev.think24.ru}/consult/student/specs`,
      {
        params: {
          document_number,
        },
      }
    );
    return data;
  },
  getSpecializationResult: async ({ specialization_id }) => {
    const { data } = await getAPIClient.get(
      `${api-consult.dev.think24.ru}/consult/specializations/result`,
      {
        params: {
          specialization_id,
        },
      }
    );
    return data;
  },
};

export default API;
