import Layout from "../components/Layout";

const h2 = {
    fontSize: '24px',
}

export const License = () => {
    return (
        <Layout>
            <div style={{maxWidth: '1000px', display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: '0 auto', paddingTop: '24px', gap: '8px'}}>
                <h1 style={{fontSize: '32px'}}>Лицензионное соглашение:</h1>
                <p>ВНИМАНИЕ: Это лицензионное соглашение ("Соглашение") устанавливает условия использования веб-сайта и его содержимого ("Сайт"). Пожалуйста, внимательно прочитайте и понимайте все условия, прежде чем использовать Сайт.</p>
                <h2 style={h2}>Авторские права</h2>
                <p>Весь контент на Сайте, включая, но не ограничиваясь, тексты, графику, логотипы, изображения, аудио- и видеоматериалы, является собственностью владельцев Сайта - ООО «Триалог» и защищен авторскими правами. Все права, не являющиеся явно предоставленными в рамках данной лицензии, остаются за владельцами Сайта (ООО «Триалог»).</p>
                <h2 style={h2}>Лицензия</h2>
                <p>Под условиями настоящей лицензии владельцы Сайта предоставляют вам ограниченное, неисключительное право использовать Сайт и его содержимое в соответствии с нижеследующими условиями:</p>
                <p>Вы можете свободно просматривать, читать и воспроизводить содержимое Сайта. <br/>
                    Вы можете делиться содержимым Сайта путем копирования и распространения, но только для некоммерческих целей. <br/>
                    Вы не можете изменять, трансформировать или создавать производные работы на основе содержимого Сайта. <br/>
                    Вы не можете использовать содержимое Сайта в коммерческих целях без предварительного письменного разрешения владельцев Сайта. <br/>
                    Вы не можете удалять или изменять указания об авторских правах или другие уведомления об интеллектуальной собственности, присутствующие на Сайте.
                </p>
                <h2 style={h2}>Отказ от гарантий</h2>
                <p>Сайт и его содержимое предоставляются "как есть", без каких-либо явных или подразумеваемых гарантий. Владельцы Сайта не гарантируют точность, надежность или полноту содержимого Сайта. Владельцы Сайта также не несут ответственности за любые убытки, прямые или косвенные, связанные с использованием Сайта.</p>
                <h2 style={h2}>Изменение условий</h2>
                <p>Владельцы Сайта оставляют за собой право в любое время изменять условия данного Соглашения. Измененные условия вступают в силу с момента их публикации на Сайте. При использовании Сайта после вступления в силу изменений вы соглашаетесь с такими изменениями.</p>
                <h2 style={h2}>Прекращение лицензии</h2>
                <p>Владельцы Сайта могут прекратить ваше право использования Сайта в любое время по своему усмотрению, если вы нарушаете условия данного Соглашения. В случае прекращения лицензии вам необходимо прекратить использование Сайта и удалить все его содержимое, которое у вас находится.</p>
                <h2 style={h2}>Коммерческое использование</h2>
                <p>Для коммерческого использования функциональных возможностей сайта, свяжитесь с нами по электронной почте ntrusova@trialogue-org.ru.</p>
                <p>Функциональность сайта и результаты работы Сайта являются интеллектуальной собственностью ООО «Триалог».</p>
                <p>Принимая условия настоящего Соглашения, вы подтверждаете, что ознакомились с ним и согласны с его условиями.</p>
            </div>
        </Layout>
    )
}
